import { ProList, ProDescriptions } from '@ant-design/pro-components'
import { App, Modal, Row, Col, Button, Image, Carousel, Drawer, FloatButton, Space, Input, Tooltip } from 'antd'
import { LinkOutlined, HeartTwoTone, DeleteOutlined, ImportOutlined, ExportOutlined, HeartOutlined } from '@ant-design/icons'
import { useRef, useState } from 'react'
import axios from '../axios'
import _ from 'lodash'
import localforage from 'localforage'

const getItemKey = (item) => {
  return item.act_id
}

function SearchResultDetailModal({ item, visible, setIsModalVisible, FavorateButton }) {
  const { message } = App.useApp()
  const [pics, setPics] = useState([])
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      key: 'act_name',
      dataIndex: 'act_name',
      title: '活动名称',
    },
    {
      key: 'tkl',
      dataIndex: 'tkl',
      title: '淘口令',
    },
    {
      key: 'favorate',
      render: (value, item) => <FavorateButton type='button' style={{ width: 100 }} item={item}></FavorateButton>
    },
    {
      key: 'link',
      render: (value, item) => {
        return (item.url ?
          <a target='_blank' rel="noreferrer" href={item.url}>
            <Button Button type='primary' style={{ width: 100 }} icon={<LinkOutlined />}>
              打开链接
            </Button>
          </a> : '-'
        )
      }
    },
    {
      key: 'deeplink',
      render: (value, item) => {
        return (item.deeplink ?
          <a target='_blank' rel="noreferrer" href={item.deeplink}>
            <Button Button type='primary' style={{ width: 100 }} icon={<LinkOutlined />}>
              打开APP
            </Button>
          </a> : null
        )
      }
    },
  ]

  return (
    <>
      <Modal
        width='90%'
        open={visible}
        footer={null}
        onCancel={handleCancel}>
        <Row justify='space-around'>
          <Col xs={24} sm={10} lg={8}>
            <Carousel autoplay arrows={true}>{pics}</Carousel>
          </Col>
          <Col xs={24} sm={12} lg={14}>
            <ProDescriptions
              column={1}
              columns={columns}
              params={{ act_id: item.act_id }}
              request={async ({ act_id }) => {
                return axios.post('/act', { act_id }, { timeout: 10000 })
                  .then(async res => {
                    var _item = res.data
                    // url 
                    let url = _item.h5 || _item.long_h5
                    res.data.url = url

                    res.data.act_id = act_id

                    const pics = Array.from(new Set([
                      _item.poster_qrcode_url,
                      _item.we_app_info?.miniCode,
                      _item.alipay_app_info?.miniCode,
                      item.poster,
                      item.img,
                    ])).filter(x => x)
                    const picElements = pics.map(x => {
                      return <div
                        key={x}
                        style={{
                          background: '#364d79',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <Image
                          style={{ maxHeight: '50vh', maxWidth: '70vw' }}
                          src={x} alt={x} />
                      </div>
                    })
                    setPics(picElements)
                    return {
                      success: true,
                      data: res.data
                    }
                  }).catch(e => {
                    message.error('数据读取失败')
                  })
              }}
            >
            </ProDescriptions>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default function Home() {
  const { modal, message } = App.useApp()
  const actionRef = useRef()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isFavorateListOpen, setIsFavorateListOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState({})
  const [favorateList, setFavorateList] = useState(new Map())
  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState(null)

  const metas = {
    title: {
      search: { transform: (value) => ({ keyword: value }) },
      render: (dom, row) => (
        <div style={{ width: 255 }} onClick={() => {
          setCurrentItem(row)
          setIsModalVisible(true)
          setIsFavorateListOpen(false)
        }}>
          <Image src={row.img} alt={row.img} preview={false} width={255}></Image>
          <div>{row.act_name}</div>
          <div style={{ color: '#000000D9', fontWeight: 'normal' }}>
            {`佣金 ${row.commission_rate}`}
          </div>
        </div>
      )
    },
    actions: {
      cardActionProps: 'actions',
      render: (text, record) => [
        <FavorateButton key='favorate' item={record}></FavorateButton>,
      ]
    },
  }

  const loadFavorateList = async () => {
    var result = (await localforage.getItem('favorateEventList')) || new Map()
    setFavorateList(result)

    return result
  }
  const saveFavorateList = async (item) => {
    if (item?.act_id) {
      var key = getItemKey(item)
      var list = _.clone(favorateList)
      if (list.has(key)) {
        list.delete(key)
      } else {
        list.set(key, item)
      }
      setFavorateList(list)
      localforage.setItem('favorateEventList', list)
    }
  }
  function FavorateButton({ item, style, type }) {
    var flag
    if (item) {
      var key = getItemKey(item)
      flag = favorateList.has(key)
    }

    if (type === 'button') {
      return <Button
        style={style}
        onClick={() => { saveFavorateList(item) }}
        icon={<HeartTwoTone twoToneColor={flag ? 'red' : 'rgb(163, 160, 157)'}></HeartTwoTone>}
      >
        收藏
      </Button>
    }

    return <a style={style} onClick={() => { saveFavorateList(item) }}>
      <HeartTwoTone twoToneColor={flag ? 'red' : 'rgb(163, 160, 157)'}></HeartTwoTone>
    </a>
  }

  function FavorateListDrawer() {
    return (
      <>
        <Drawer
          open={isFavorateListOpen}
          onClose={() => setIsFavorateListOpen(!isFavorateListOpen)}
          title='收藏列表'
          size='large'
          extra={[
            <Tooltip
              key='copy'
              title='复制到剪贴板'>
              <Button
                onClick={async () => {
                  try {
                    const text = JSON.stringify(Array.from(favorateList.values()))
                    await navigator.clipboard.writeText(text);
                    message.success(`${favorateList.size}个商品已复制到剪贴板`);
                  } catch (error) {
                    message.error('复制失败');
                    console.log(error)
                  }
                }}
                icon={<ExportOutlined />}
              ></Button>
            </Tooltip>,
            <Tooltip
              key='paste'
              title='从剪贴板导入'>
              <Button
                onClick={async () => {
                  try {
                    const clipboardContent = await navigator.clipboard.readText();
                    var newItems = JSON.parse(clipboardContent)
                    var newList = _.clone(favorateList)
                    newItems.forEach(item => {
                      var key = getItemKey(item)
                      newList.set(key, item)
                    })
                    setFavorateList(newList)
                    localforage.setItem('favorateEventList', newList)
                    message.success(`${newItems.length}个商品已导入到收藏列表`);
                  } catch (error) {
                    message.error('导入失败');
                    console.log(error)
                  }
                }}
                icon={<ImportOutlined />}
              ></Button>
            </Tooltip>,
            <Tooltip
              key='clear'
              title='清空收藏列表'>
              <Button
                onClick={() => {
                  modal.confirm({
                    title: '确认清空收藏列表？',
                    icon: <DeleteOutlined />,
                    content: '清空收藏列表后，您将无法恢复',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                      localforage.setItem('favorateEventList', new Map())
                        .then(() => { loadFavorateList() })
                    },
                    onCancel() {
                      console.log('Cancel');
                    },
                  });
                }}
                icon={<DeleteOutlined />}
                danger
              ></Button>
            </Tooltip>,
          ]}
        >
          <ProList
            dataSource={Array.from(favorateList.values())}
            grid
            itemCardProps={{ bodyStyle: { padding: 0 } }}
            metas={{
              title: metas.title,
              actions: metas.actions,
            }}
            search={false}
            pagination={false}
          ></ProList>
        </Drawer>
      </>
    )
  }
  function SearchForm() {
    return (
      <Space>
        <Input.Search
          allowClear
          onSearch={(value) => { setKeyword(value) }}
          defaultValue={keyword}
          placeholder='活动名称（正则表达式）'
        ></Input.Search>
      </Space>
    )
  }
  return (
    <>
      <SearchResultDetailModal
        item={currentItem}
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        FavorateButton={FavorateButton}
      ></SearchResultDetailModal>
      <FavorateListDrawer></FavorateListDrawer>

      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton
          onClick={() => setIsFavorateListOpen(!isFavorateListOpen)}
          icon={<HeartOutlined />}
        />
        <FloatButton.BackTop visibilityHeight={0} />
      </FloatButton.Group>

      <ProList
        actionRef={actionRef}
        itemCardProps={{
          bodyStyle: { padding: 0 },
        }}
        grid
        search={false}
        toolBarRender={() => <SearchForm></SearchForm>}
        rowKey='act_id'
        params={{ keyword }}
        request={async ({ pageSize, current, keyword }) => {
          loadFavorateList()
          if (data.length > 0) {
            const filtered = data.filter(x => new RegExp(keyword).test(x.act_name))
            return new Promise((resolve) => resolve({
              data: filtered,
              total: filtered.length,
              success: true,
            }))
          }

          return axios.post('/act_list', { pageSize, page: current, }, { timeout: 10000 })
            .then(res => {
              var data = res.data.data
              setData(data)
              return {
                data: data,
                total: data.length,
                success: true,
              }
            }).catch(e => {
              message.error('数据读取失败')
            })
        }}
        pagination={{
          pageSize: 100,
        }}
        metas={metas}
      ></ProList>
    </>
  )
}