export const platformEnum = {
	taobao: { text: '淘宝', icon: "https://img.jutuike.com/jutuike/jtk-taobao-icon.png" },
	jd: { text: '京东', icon: "https://img.jutuike.com/jutuike/jtk-jd-icon.png" },
	pdd: { text: '拼多多', icon: "https://img.jutuike.com/jutuike/jtk-pdd-icon.png" },
	vip: { text: '唯品会', icon: "https://img.jutuike.com/jutuike/jtk-vip-icon.png" },
	kaola: { text: '考拉', icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAEkElEQVR4Ab2XRXjrRhSFHU7MbJkZ5RjC/IWZmZMyMzN3W2bm7vfl1pviMpsys7/HfKpIluOJ4j49nE1inN/n3LlzrkTsAk2XwhjqhSH4OPT0uzDGNkAld8FSswvW+g3YGt+Fs+1xuDp6Qc+USk7XgsFFQeV8ElpPGlofoAsChghgrASoBGCuAax1gK0JcLQCrnbA052Gp+9J0IPUyW/s85VCabkXSuseKO2A2o0tABqMAoBpE6AajAJbAM52wN0FeHoB/+AeBEfvxcAVJ6YIZAYKUkMKcgpQWACVgwPQZAD0vALJjAL1gD1HAR7ANwj4h4HQRIqxhRK5uSaGCu0PDAAgM20BaNzIa4GlTqiAtw/wDXAAwXEgMvUDKpeix//l5aofUK7BFoAVUG4q4CIVMMUyCvAWNJIK8ACBEQ4gNAnQsz+gdp3K73mJPIUyJVCxCaDHjhboswpwNWAhLYBTYAEHEJ4CIrNA5WJqx5pAqfRelEjBAWgzACYSQLtdAb4IMxbYWzIA3SwAYUEoA0AvAJXL926TXkahuHwPSmUcQHkeBcgaICwQKODNo0B0AYit7CGsQFHJkwwAUCIjFVBQu6C0pqBypqBx7yFPQewnmKs+hKU6xQD8QfQBd89fDMDH8A19xgAcIBSIsgoAibUnsx2OAUgTAOWaQ5AZr4fJJM1CWqqlMAT6YQiug4pHCAXvvrsQttY6OFvPh6ujDe13F2dfCyzoEZ54mAWgcwHW05i5u1SC4uJeFBaDBeBrQKoflZzmhfD0jTk1AMTXgKoLeiUoLHochSXIKlCueltyhhboua+yALFNgPMel0BS8C6KOAC2CKXq0TMIcDOivALrQOK8dzcBNliAojJOAZk6IfoLrbUJ2Bp94gEW5hFdzAXYkKCgcBdhQZmyX9SXUbFV7jKqPQJnY5M4gPmrQbPHkLWAAdjFAhAKlKuezX810xQMEVYhGGMvg0pyndBWfzP7nKe9BcFReX6A2Q8RzS1CDmCDPwWZRnQIUs3gjl+g8z0OXegb9n9T5as5eeBWxHqlcLQegbv90p03n75acAoYC4giZBUoZRvRPsiMy6BpomfDlVDDEPbxCmTzgK3hRk6Btihc6+XEZxhFEB67ke+EvAV8EQqPIdmKf4PC8iC0XpvgF5nisxkLDsDeXCN43TsUhX/oaQRGdhGdkLTgcbIRlbIKcABEHnD+BqM7JtjEUeuBs5USbt7bD2/fPuIyCpMAiPGNiG/FRWWkAoI84P6Nl///FtuK3V37dswDEWErznMZ5c0DaRiC56K9vViwcfWIFM622xmAA/nzAHkZCa9jsXnAQP8GKv4iqOTtsFTfC2v9m7A17xKXB/jr+FJKEEjOWh4gAwkZyQgFRKRiQSx3ZRUgAULCSCY2lOaPZPnnAjKU8gBRPpSKiuX6nFNgz2+BJRPL7VwmJOcCHmCMiOUiBxNjdjARApCDCWlBB69AzmAyJnowIWtCTnGjmaAGiFQstMDFj2YDwtHspIdTjSfNzQWhjALbARrJ4dQnejg9ifHcEOXGc1NiF8yZ8dzefFLj+X94Hrn/ddwuMgAAAABJRU5ErkJggg==" },
	douyin: { text: '抖音', icon: "https://img.jutuike.com/jutuike/jtk-douyin-icon.png" }
}

/**
 * 1-综合排序,2-价格升序,3-销量降序,4-价格降序,5-销量升序,6-佣金比例降序（唯品会不支持） 7-佣金比例升序（唯品会不支持） 默认1
 */
export const sortEnum = {
	1: { text: '综合排序' },
	2: { text: '价格升序' },
	3: { text: '销量降序' },
	4: { text: '价格降序' },
	5: { text: '销量升序' },
	6: { text: '佣金比例降序（唯品会不支持）' },
	7: { text: '佣金比例升序（唯品会不支持）' },
}
