import { Link } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme, Row, Col } from 'antd';
import { GiftOutlined, ShopOutlined, } from "@ant-design/icons";
const { Header, Content, Footer } = Layout;

export default function App({ contents }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className="layout">
      <Header
      // style={{
      //   display: 'flex',
      //   alignItems: 'center',
      // }}
      >
        <Row justify='center'>
          <Col
            className="site-layout-content"
            style={{
              background: colorBgContainer,
              maxWidth: 1200,
            }}
            span={24}
          >
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ minWidth: 300 }}
              items={[
                { key: 1, label: <Link to="/">商城</Link>, icon: <ShopOutlined />},
                { key: 2, label: <Link to="/events">活动</Link>,icon:<GiftOutlined /> },
              ]}
            />
          </Col>
        </Row>
      </Header>
      <Content>
        <Row justify='center'>
          <Col
            className="site-layout-content"
            style={{
              background: colorBgContainer,
              maxWidth: 1200,
            }}
            span={24}
          >
            {/* <Row justify='start'>
              <Col>
                <Breadcrumb
                  style={{
                    margin: '16px 0',
                  }}
                >
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item>List</Breadcrumb.Item>
                  <Breadcrumb.Item>App</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row> */}
            {contents}
          </Col>
        </Row>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
      </Footer>
    </Layout>
  );
};