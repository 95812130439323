import { ProList, ProDescriptions, BetaSchemaForm } from '@ant-design/pro-components'
import { App, Modal, Carousel, Image, Row, Col, Button, Tooltip, FloatButton, Drawer, Space, Input } from 'antd'
import { LinkOutlined, HeartTwoTone, DeleteOutlined, ExportOutlined, ImportOutlined, ReadOutlined, SnippetsOutlined, HeartOutlined } from '@ant-design/icons'
import { useRef, useState } from 'react'
import { platformEnum, sortEnum } from '../config'
import axios from '../axios'
import QRcode from 'qrcode'
import _ from 'lodash'
import localforage from 'localforage'

function getUniqueValues(arr) {
  var values = arr.flat()
  var set = new Set(values)
  return Array.from(set)
}

const getItemKey = (item) => {
  var key
  switch (item.source) {
    case 'taobao':
      key = `${item.goodsName}@${item.shopName}`
      break
    default:
      key = item.goodsId
  }
  return key
}

function SearchResultDetailModal({ item, visible, setIsModalVisible, FavorateButton }) {
  const { message } = App.useApp()
  const [pics, setPics] = useState([])
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      key: 'goodsName',
      dataIndex: 'goodsName',
      title: '商品名称',
      span: 3
    },
    {
      key: 'shopName',
      dataIndex: 'shopName',
      title: '店铺名称',
      span: 3,
      render: (dom, row) => <>
        <img src={platformEnum[row.source]?.icon} alt={row.source} style={{ width: 16 }}></img>
        {row.shopName}
      </>
    },
    {
      key: 'marketPrice',
      dataIndex: 'marketPrice',
      title: '市场价',
    },
    {
      key: 'discount',
      dataIndex: 'discount',
      title: '折扣/券面额',
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: '折后价',
    },
    {
      key: 'finalPrice',
      dataIndex: 'finalPrice',
      title: '预估价格',
      render: (dom, row) => <div style={{ color: 'red', fontWeight: 'bold' }}>{row.finalPrice}</div>
    },
    {
      key: 'commission',
      dataIndex: 'commission',
      title: '佣金金额',
    },
    {
      key: 'commissionRate',
      dataIndex: 'commissionRate',
      title: '佣金比例',
    },
    {
      key: 'discountInfo',
      title: '券信息',
      span: 3,
      render: (value, item) => item.discount != 0 ?
        `￥${item.couponInfo?.fav} (${item.couponInfo?.useBeginTime} - ${item.couponInfo?.useEndTime})`
        : '无'
    },
    {
      key: 'favorate',
      render: (value, item) => <FavorateButton type='button' style={{ width: 100 }} item={item}></FavorateButton>
    },
    {
      key: 'link',
      render: (value, item) => {
        function LinkButton({ text, url, qr }) {
          const image = qr ? <Image src={qr} alt={item.url}
            style={{ maxHeight: 150, maxWidth: 150 }}
          ></Image> : null
          return (<>
            <div>
              <a target='_blank' rel="noreferrer" href={url}>
                <Button Button type='primary' style={{ width: 100 }}>
                  <LinkOutlined /> {text}
                </Button>
              </a>
            </div>
            <div>
              {image}
            </div>
          </>
          )
        }

        const link = item.url ?
          <LinkButton text='打开链接' url={item.url} qr={item.qr} /> :
          null
        const deepLink = item.deeplinkUrl ?
          <LinkButton text='打开APP' url={item.deeplinkUrl} qr={item.deeplink_qr} /> :
          null
        return (
          <Space wrap>
            {link}
            {deepLink}
          </Space>
        )
      }
    },
  ]

  return (
    <>
      <Modal
        width='90%'
        style={{ maxWidth: 1200 }}
        open={visible}
        footer={null}
        onCancel={handleCancel}>
        <Row justify='space-around'>
          <Col xs={24} sm={10} lg={8}>
            <Carousel autoplay arrows={true}>{pics}</Carousel>
          </Col>
          <Col xs={24} sm={12} lg={14}>
            <ProDescriptions
              columns={columns}
              params={{ goodsId: item.goodsId, source: item.source }}
              request={async ({ goodsId, source }) => {
                if (!goodsId) {
                  return { success: true, data: {} }
                }
                return axios.post('/convert', { goodsId, source }, { timeout: 10000 })
                  .then(async res => {
                    var item = res.data
                    // url & qr code
                    let url
                    let deeplinkUrl
                    url = item.click_url ? item.click_url : item.url?.match(/(https?:\/\/[^\s]+)/)?.pop()
                    url = item.schema_url ? item.schema_url : url
                    switch (item.source) {
                      case 'taobao':
                        url = item.short_click_url
                        break
                      case 'pdd':
                        url = item.url
                        deeplinkUrl = item.schema_url
                        break
                      case 'douyin':
                        url = null
                        deeplinkUrl = item.click_url
                        break
                      default:
                        url = item.url
                        deeplinkUrl = item.deeplinkUrl
                    }
                    res.data.qr = url ? await QRcode.toDataURL(url) : null
                    res.data.url = url
                    res.data.deeplinkUrl = deeplinkUrl
                    res.data.deeplink_qr = deeplinkUrl ? await QRcode.toDataURL(deeplinkUrl) : null
                    // final price
                    res.data.finalPrice = (item.price - item.commission * 0.85).toLocaleString('zh-cn', { style: "currency", currency: "CNY" })

                    const pics = getUniqueValues([
                      item.goodsCarouselPictures || [],
                      item.goodsDetailPictures || []
                    ])
                    const picElements = pics.map(x => {
                      return <div
                        key={x}
                        style={{
                          background: '#364d79',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <Image
                          style={{ maxHeight: '50vh', maxWidth: '70vw' }}
                          src={x} alt={x} />
                      </div>
                    })
                    setPics(picElements)
                    res.data.goodsThumbUrl = pics[0]

                    return {
                      success: true,
                      data: res.data
                    }
                  })
                  .catch(e => {
                    message.error('数据读取失败')
                  })
              }}
            >
            </ProDescriptions>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default function Home() {
  const { message, modal } = App.useApp()
  const actionRef = useRef()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isFavorateListOpen, setIsFavorateListOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState({})
  const [favorateList, setFavorateList] = useState(new Map())
  const [keyword, setKeyword] = useState(null)
  const [source, setSource] = useState('taobao')
  const [sort, setSort] = useState('1')

  const metas = {
    title: {
      dataIndex: 'goodsName',
      search: { transform: (value) => ({ keyword: value }) },
      render: (dom, row) => (
        <div style={{ width: 255 }} onClick={() => {
          setCurrentItem(row)
          setIsModalVisible(true)
          setIsFavorateListOpen(false)
        }}>
          <Image src={row.goodsThumbUrl} alt={row.goodsThumbUrl} preview={false} width={255}></Image>
          <Tooltip title={row.goodsName}><div>{row.goodsName}</div></Tooltip>
          <div style={{ color: '#000000D9', fontWeight: 'normal' }}>
            <img src={platformEnum[row.source].icon} alt={row.source} style={{ width: 16 }}></img>
            {row.shopName}  销量{row.sales_tip}
          </div>
          <div style={{ color: '#000000D9', fontWeight: 'normal' }}>
            <span style={{ color: 'red', fontWeight: 'bold', fontSize: '1.3em' }}>
              {`${(row.price - row.commission * 0.85)?.toLocaleString('zh-cn', { style: "currency", currency: "CNY" })}`}
            </span>
            <span style={{ color: '#00000099', textDecoration: 'line-through' }}>{parseFloat(row.price).toLocaleString('zh-cn', { style: "currency", currency: "CNY" })}</span>
            <span>{` 佣金${parseFloat(row.commission).toLocaleString('zh-cn', { style: "currency", currency: "CNY" })}(${row.commissionRate}%)`}</span>
          </div>
        </div>
      )
    },

    actions: {
      cardActionProps: 'actions',
      render: (text, record) => [
        <FavorateButton key='favorate' item={record}></FavorateButton>,
      ]
    },
  }

  const loadFavorateList = async () => {
    var result = (await localforage.getItem('favorateList')) || new Map()
    setFavorateList(result)

    return result
  }
  const saveFavorateList = async (item) => {
    if (item?.source) {
      var key = getItemKey(item)
      var list = _.clone(favorateList)
      if (list.has(key)) {
        list.delete(key)
      } else {
        list.set(key, item)
      }
      setFavorateList(list)
      localforage.setItem('favorateList', list)
    }
  }
  function FavorateButton({ item, style, type }) {
    var flag
    if (item) {
      var key = getItemKey(item)
      flag = favorateList.has(key)
    }
    if (type === 'button') {
      return <Button
        style={style}
        onClick={() => { saveFavorateList(item) }}
        icon={<HeartTwoTone twoToneColor={flag ? 'red' : 'rgb(163, 160, 157)'}></HeartTwoTone>}
      >
        收藏
      </Button>
    }
    return <a style={style} onClick={() => { saveFavorateList(item) }}>
      <HeartTwoTone twoToneColor={flag ? 'red' : 'rgb(163, 160, 157)'}></HeartTwoTone>
    </a>
  }

  function FavorateListDrawer() {
    return (
      <>
        <Drawer
          open={isFavorateListOpen}
          onClose={() => setIsFavorateListOpen(!isFavorateListOpen)}
          title='收藏列表'
          size='large'
          extra={[
            <Tooltip
              key='copy'
              title='复制到剪贴板'>
              <Button
                onClick={async () => {
                  try {
                    const text = JSON.stringify(Array.from(favorateList.values()))
                    await navigator.clipboard.writeText(text);
                    message.success(`${favorateList.size}个商品已复制到剪贴板`);
                  } catch (error) {
                    message.error('复制失败');
                    console.log(error)
                  }
                }}
                icon={<ExportOutlined />}
              ></Button>
            </Tooltip>,
            <Tooltip
              key='paste'
              title='从剪贴板导入'>
              <Button
                onClick={async () => {
                  try {
                    const clipboardContent = await navigator.clipboard.readText();
                    var newItems = JSON.parse(clipboardContent)
                    var newList = _.clone(favorateList)
                    newItems.forEach(item => {
                      var key = getItemKey(item)
                      newList.set(key, item)
                    })
                    setFavorateList(newList)
                    localforage.setItem('favorateList', newList)
                    message.success(`${newItems.length}个商品已导入到收藏列表`);
                  } catch (error) {
                    message.error('导入失败');
                    console.log(error)
                  }
                }}
                icon={<ImportOutlined />}
              ></Button>
            </Tooltip>,
            <Tooltip
              key='clear'
              title='清空收藏列表'>
              <Button
                onClick={() => {
                  modal.confirm({
                    title: '确认清空收藏列表？',
                    icon: <DeleteOutlined />,
                    content: '清空收藏列表后，您将无法恢复',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                      localforage.setItem('favorateList', new Map())
                        .then(() => { loadFavorateList() })
                    },
                    onCancel() {
                      console.log('Cancel');
                    },
                  });
                }}
                icon={<DeleteOutlined />}
                danger
              ></Button>
            </Tooltip>,
          ]}
        >
          <ProList
            dataSource={Array.from(favorateList.values())}
            grid
            itemCardProps={{ bodyStyle: { padding: 0 } }}
            metas={{
              title: metas.title,
              actions: metas.actions,
            }}
            search={false}
            pagination={false}
          ></ProList>
        </Drawer>
      </>
    )
  }

  function SearchForm() {
    return (
      <Space wrap>
        <Input.Search
          allowClear
          onSearch={(value) => {
            if (value === keyword) {
              actionRef.current.reload()
            }
            else if (!_.isEmpty(value)) {
              setKeyword(value)
            }
          }}
          defaultValue={keyword}
          placeholder='关键词/链接/淘口令'
        ></Input.Search>
        <BetaSchemaForm layoutType='LightFilter'
          onFinish={(values) => {
            if (values.source) {
              setSource(values.source)
            }
            if (values.sort) {
              setSort(values.sort)
            }
            if (values.keyword) {
              setKeyword(values.keyword)
            }
          }}
          columns={[
            {
              key: 'source',
              name: 'source',
              valueType: 'select',
              valueEnum: platformEnum,
              initialValue: source,
              fieldProps: { allowClear: false }
            },
            {
              key: 'sort',
              name: 'sort',
              valueType: 'select',
              valueEnum: sortEnum,
              initialValue: sort,
              fieldProps: { allowClear: false }
            }
          ]}
        ></BetaSchemaForm>
      </Space>
    )
  }

  return (
    <>
      <SearchResultDetailModal
        item={currentItem}
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        FavorateButton={FavorateButton}
      ></SearchResultDetailModal>
      <FavorateListDrawer></FavorateListDrawer>
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton
          tooltip='收藏列表'
          onClick={() => setIsFavorateListOpen(!isFavorateListOpen)}
          icon={<HeartOutlined />}
        />
        <FloatButton
          tooltip='读取剪贴板'
          onClick={() => {
            navigator.clipboard.readText().then(text => {
              if (text === keyword) {
                actionRef.current.reload()
              }
              else {
                setKeyword(text)
              }
            })
          }}
          icon={<SnippetsOutlined />}></FloatButton>
        <FloatButton.BackTop tooltip='回到顶部' visibilityHeight={0} />
      </FloatButton.Group>
      <ProList
        actionRef={actionRef}
        grid
        itemCardProps={{
          bodyStyle: { padding: 0 },
        }}
        search={false}
        toolBarRender={(action, rows) => <SearchForm></SearchForm>}
        defaultSize={20}
        rowKey='goodsId'
        params={{ keyword, source, sort }}
        request={async ({ pageSize, current, keyword, source, sort }) => {
          loadFavorateList()
          if (_.isEmpty(keyword)) {
            return new Promise(resolve => resolve({ data: [], success: true }))
          }
          return axios.post('/search', { pageSize, page: current, keyword, source, sort }, { timeout: 10000 })
            .then(res => {
              return res.data
            })
            .catch(e => {
              message.error('数据读取失败')
            })
        }}
        pagination={{
          // pageSize: 10,
        }}
        metas={metas}
      ></ProList>
    </>
  )
}
