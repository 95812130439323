import {
  createHashRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { App, ConfigProvider, theme } from 'antd'
import Home from './routes/Home'
import Events from './routes/Events'
import Layout from './components/Layout'

const routes = [
  {
    path: "/",
    element: <Layout contents={<Outlet />} />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "events",
        element: <Events />,
      },
    ]
  },

]

const router = createHashRouter(routes)

function Dom() {
  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: 'brown' },
        algorithm: [
          // theme.darkAlgorithm,
          theme.compactAlgorithm,
        ]
      }}
    >
      <App>
        <RouterProvider router={router} />
      </App>
    </ConfigProvider>
  );
}

export default Dom;
